import WhatsAppButton from "../whatsApp/whatsApp";

const Footer = () => {

    const data = new Date();
    const anoAtual = data.getFullYear();

    return (
      <div class="container">
        <footer class="py-3 my-4">
          <ul class="nav justify-content-center border-bottom pb-3 mb-3">
            {/* <li class="nav-item">
              <a href="#" class="nav-link px-2 text-body-secondary">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link px-2 text-body-secondary">
                Sobre
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link px-2 text-body-secondary">
                Serviços
              </a>
            </li> */}
          </ul>
                <p class="text-center text-body-secondary">© {anoAtual} V.W.A Systems</p>
                <WhatsAppButton/>
        </footer>
      </div>
    );
}

export default Footer;