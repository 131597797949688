import React from "react";
import "./whatsApp.css";


const WhatsAppButton = () => {
  // Define a URL para redirecionamento com base no user agent
  const whatsappUrl = () => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|Android|webOS|BlackBerry|iPod/i.test(userAgent);

    const phoneNumber = "5575992059112";
    if (isMobile) {
      return `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    }
    return `https://web.whatsapp.com/send?phone=${phoneNumber}`;
  };

  return (
    <a href={whatsappUrl()} target="_blank" rel="noopener noreferrer">
      <div className="btn-whatsapp pulsaDelay">
        <i className="fa fa-whatsapp"></i>
      </div>
    </a>
  );
};

export default WhatsAppButton;
