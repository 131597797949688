import './slide.css'


const Slide = ()=>{

    return (
        <section className='container-slide'>
        <p className='slide-capa'>
          Impressione seus clientes desde o primeiro clique com um site profissional e intuitivo
        </p>
      </section>
    )
}


export default Slide