import React from "react";
import "./ChamadaAcao.css"; // Estilos CSS para o componente

const ChamadaAcao = () => {
  const whatsappUrl = () => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|Android|webOS|BlackBerry|iPod/i.test(userAgent);

    const phoneNumber = "5575992059112";
    if (isMobile) {
      window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    } else {
      window.location.href = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    }
  };

  return (
    <div className="bg-dark text-secondary px-4 py-5 text-center">
      <div className="py-5">
        <h1 className="display-5 fw-bold text-white">
          Transforme Suas Ideias em Realidade
        </h1>
        <div className="col-lg-6 mx-auto">
          <p className="fs-5 mb-4">
            Nós oferecemos desenvolvimento de software personalizado,
            consultoria tecnológica, e muito mais para ajudar seu negócio a
            prosperar na era digital.
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button
              type="button"
              className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
              onClick={whatsappUrl}
            >
              Fale com um Especialista
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChamadaAcao;
