import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Servicos.css'; // Importando os estilos personalizados

const services = [
  {
    title: 'Desenvolvimento de Aplicativos Web',
    description: 'Criamos soluções web personalizadas para atender às suas necessidades de negócios.',
    icon: 'bi bi-laptop',
  },
  {
    title: 'Desenvolvimento de Aplicativos Móveis',
    description: 'Desenvolvemos aplicativos móveis intuitivos e de alta performance.',
    icon: 'bi bi-phone',
  },
  {
    title: 'Consultoria em Tecnologia',
    description: 'Oferecemos consultoria especializada para transformar sua empresa com tecnologia.',
    icon: 'bi bi-lightbulb',
  },
  {
    title: 'Manutenção e Suporte de Software',
    description: 'Fornecemos suporte contínuo para garantir o funcionamento ideal de seus sistemas.',
    icon: 'bi bi-tools',
  },
  {
    title: 'Integração de Sistemas',
    description: 'Integramos sistemas diversos para otimizar seus processos de negócios.',
    icon: 'bi bi-link',
  },
  {
    title: 'SEO e Otimização de Performance',
    description: 'Melhoramos o desempenho e a visibilidade do seu site nos mecanismos de busca.',
    icon: 'bi bi-graph-up',
  },
];

const Servicos = () => {
  return (
    <section id="services" className="services section py-5">
      <div className="container section-title text-center mb-5" data-aos="fade-up">
        <h2 className="display-4 font-weight-bold">Serviços</h2>
        <p className="lead">Confira nossos serviços</p>
      </div>
      <div className="container">
        <div className="row gy-4">
          {services.map((service, index) => (
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay={(index + 1) * 100}
              key={index}
            >
              <div className="service-item position-relative bg-light p-4 rounded shadow-sm">
                <div className="icon bg-primary text-white rounded-circle mb-3 d-flex align-items-center justify-content-center">
                  <i className={`${service.icon} display-4`}></i>
                </div>
                <h2  className="stretched-link text-decoration-none">
                  <h3 className="h5 font-weight-bold text-dark">{service.title}</h3>
                </h2>
                <p className="text-muted">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Servicos;
