import React from "react";
import imgArgumento from '../../assets/argumento.jpg'

const Argumento = () => {
    const whatsappUrl = () => {
      const userAgent = navigator.userAgent;
      const isMobile = /iPhone|Android|webOS|BlackBerry|iPod/i.test(userAgent);

      const phoneNumber = "5575992059112";
      if (isMobile) {
        window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
      } else {
        window.location.href = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
      }
    };

  return (
    <div className="container col-xxl-8 px-4 py-5">
      <div className="row flex-lg-row align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <img
            src={imgArgumento}
            className="d-block mx-lg-auto img-fluid"
            alt="Imagem ilustrativa de soluções de software personalizadas da V.W.A Systems"
            width="700"
            height="500"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">
            Transforme suas ideias em soluções inovadoras com a V.W.A Systems
          </h1>
          <p className="lead">
            Imagine ter ao seu lado uma equipe que não apenas desenvolve
            software, mas cria soluções personalizadas que impulsionam o seu
            negócio para o sucesso. Na V.W.A Systems, combinamos experiência
            técnica com inovação para fornecer soluções de software de alta
            qualidade que atendem às suas necessidades específicas.
          </p>
          <ul className="list-unstyled text-body-emphasis">
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Desenvolvimento de Software
              Personalizado para maximizar sua eficiência operacional.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Consultoria em Tecnologia
              para otimizar seus processos empresariais.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Manutenção e Suporte Contínuo
              para garantir a performance ideal do seu sistema.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Integração de Sistemas para
              uma operação mais integrada e eficiente.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> SEO e Otimização de
              Performance para aumentar sua visibilidade online.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Utilização de Tecnologias de
              Ponta para garantir soluções modernas e escaláveis.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Metodologias Ágeis para
              entrega de projetos no prazo e dentro do orçamento.
            </li>
            <li className="mb-3">
              <i className="bi bi-check2-all"></i> Compromisso com a Excelência
              para superar suas expectativas.
            </li>
          </ul>
          <p className="lead">
            Não perca tempo com soluções genéricas. Venha para a V.W.A Systems e
            transforme suas ideias em realidade com soluções de software
            personalizadas que impulsionam o seu sucesso. Entre em contato
            conosco hoje mesmo!
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg px-4"
              onClick={whatsappUrl}
            >
              Solicite uma Consulta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Argumento;
