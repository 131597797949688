// celulares até 600px 
// tablet de 601px até 1024px 
// computadores acima de 1024px 



// CORES



import Header from './Componentes/header/header';


import './App.css'
import Slide from './Componentes/slide/slide';
import Sobre from './Componentes/Sobre/sobre';
import Clientes from './Componentes/clientes/clientes';
import Servicos from './Componentes/servicos/servicos.js'
import ChamadaAcao from './Componentes/chamadaAcao/chamdaAcao.js'
import Argumento from './Componentes/argumento/argumento.js'
import Footer from './Componentes/footer/footer.js'


function App() {

  return (
    <>
      <Header />
      <Slide />
      <Sobre />
      <Clientes />
      <Servicos />
      {/* <Premios/> /INSTAGRAM*/}
      <ChamadaAcao />
      <Argumento />
      {/* <Contato/> */}
      <Footer/>


    </>
  );
}

export default App;
