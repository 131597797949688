import React from "react";
import "./sobre.css"; // Estilos CSS para o componente
import imgSobre from '../../assets/sobre.jpg'

const Sobre = () => {
  return (
    <section id="about" className="container about section py-5">
      <div className="container">
        <div className="row flex-lg-row-reverse align-items-center g-5">
          <div className="col-lg-6">
            <img
              src={imgSobre}
              className="d-block mx-lg-auto img-fluid"
              alt="Desenvolvimento de Software"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">
              Transformando Ideias em Soluções Inovadoras
            </h1>
            <p className="lead fst-italic">
              Bem-vindo à V.W.A Systems, onde combinamos expertise técnica com
              inovação para entregar soluções de software de alta qualidade que
              atendem às necessidades específicas de cada cliente.
            </p>
            <ul className="list-unstyled">
              <li className="mb-3">
                <i className="bi bi-check2-all"></i>
                <span>
                  Desenvolvimento de software personalizado para atender aos
                  objetivos do seu negócio.
                </span>
              </li>
              <li className="mb-3">
                <i className="bi bi-check2-all"></i>
                <span>
                  Consultoria em tecnologia para otimizar seus processos e
                  aumentar sua eficiência.
                </span>
              </li>
              <li className="mb-3">
                <i className="bi bi-check2-all"></i>
                <span>
                  Manutenção e suporte contínuo para garantir a performance
                  ideal do seu sistema.
                </span>
              </li>
            </ul>
            <p>
              Nosso compromisso é fornecer soluções que não apenas atendam, mas
              superem as expectativas dos nossos clientes. Trabalhamos com
              tecnologias de ponta e práticas de desenvolvimento ágeis para
              garantir que cada projeto seja entregue no prazo e dentro do
              orçamento.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sobre;
