import React, { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import "./clientes.css";

const Clientes = () => {
  useEffect(() => {
    new Swiper(".init-swiper", {
      loop: true,
      speed: 600,
      autoplay: {
        delay: 5000,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 80,
        },
        992: {
          slidesPerView: 6,
          spaceBetween: 120,
        },
      },
    });
  }, []);

  return (
    <section id="clients" className="clients section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper init-swiper">
          <div className="swiper-wrapper align-items-center">
            <div className="swiper-slide">
              <img
                src="https://sanitymilk.com.br/images/logo.png"
                className="img-fluid"
                alt="Client 1"
              />
            </div>
            <div className="swiper-slide">
              <img
                src="https://ordenhadeirasfortagro.com.br/static/media/logoFortAgro.bcd14c7ad5b6f3448c35.png"
                className="img-fluid"
                alt="Client 2"
              />
            </div>
            <div className="swiper-slide">
              <img
                src="https://lh3.googleusercontent.com/p/AF1QipPeyhd4TPxVs_dDRlHyooUBCUxPJ1OLZ0vEFHjP=s680-w680-h510"
                className="img-fluid"
                alt="Client 3"
              />
            </div>
            <div className="swiper-slide">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbZHRmhBY2qrMFfFQW30VDP1Cke3Xkmh-xRA&s"
                className="img-fluid"
                alt="Client 4"
              />
            </div>
            {/* <div className="swiper-slide">
              <img
                src="https://sanitymilk.com.br/images/logo.png"
                className="img-fluid"
                alt="Client 5"
              />
            </div> */}
            {/* <div className="swiper-slide">
              <img
                src="https://sanitymilk.com.br/images/logo.png"
                className="img-fluid"
                alt="Client 6"
              />
            </div> */}
            {/* <div className="swiper-slide">
              <img
                src="https://sanitymilk.com.br/images/logo.png"
                className="img-fluid"
                alt="Client 7"
              />
            </div> */}
            {/* <div className="swiper-slide">
              <img
                src="https://sanitymilk.com.br/images/logo.png"
                className="img-fluid"
                alt="Client 8"
              />
            </div> */}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export default Clientes;
