import React from 'react';
import './style.css';
import logo from '../../assets/logo-vwa.png';

const Header = () => {
    return (
        <header>
            <div className="container-logo">
                <img className="logo" src={logo} alt="Imagem da logo" />
            </div>
            {/* <div>
                <nav>
                    <ul className="container-nav-ul">
                        <li><a href="https://vwasystems.com.br/" target="_blank" rel="noreferrer">HOME</a></li>
                        <li><a href="https://vwasystems.com.br/" target="_blank" rel="noreferrer">SOBRE</a></li>
                        <li><a href="https://vwasystems.com.br/" target="_blank" rel="noreferrer">SERVIÇOS</a></li>
                        <li><a href="https://vwasystems.com.br/" target="_blank" rel="noreferrer">CONTATOS</a></li>
                        <li><a href="https://vwasystems.com.br/" target="_blank" rel="noreferrer">NOTÍCIAS</a></li>
                        <li><a href="https://vwasystems.com.br/" target="_blank" rel="noreferrer">ORÇAMENTO</a></li>
                    </ul>
                </nav>
            </div> */}
        </header>
    );
};

export default Header;
